class InvitationStateEnum {
  static PENDING = 'pending';
  static ACCEPTED = 'accepted';
  static EXPIRED = 'expired';

  static labelKey(item: string) {
    return InvitationStateEnum.meta(item)?.labelKey;
  }

  static tagType(item: string) {
    return InvitationStateEnum.meta(item)?.tagType;
  }

  static meta(item: string) {
    switch (item) {
      case InvitationStateEnum.PENDING:
        return {
          labelKey: 'global.statuses.pending',
          tagType: 'warning',
        };
      case InvitationStateEnum.ACCEPTED:
        return {
          labelKey: 'global.statuses.accepted',
          tagType: 'success',
        };
      case InvitationStateEnum.EXPIRED:
        return {
          labelKey: 'global.statuses.expired',
          tagType: 'grey',
        };
    }
  }

  static all() {
    return [
      {
        key: InvitationStateEnum.PENDING,
        labelKey: InvitationStateEnum.labelKey(InvitationStateEnum.PENDING),
      },
      {
        key: InvitationStateEnum.ACCEPTED,
        labelKey: InvitationStateEnum.labelKey(InvitationStateEnum.ACCEPTED),
      },
      {
        key: InvitationStateEnum.EXPIRED,
        labelKey: InvitationStateEnum.labelKey(InvitationStateEnum.EXPIRED),
      },
    ];
  }
}

export default InvitationStateEnum;
